.grayscale
{
    filter: grayscale(100%);
}

.brand-color
{
    color: $brand-color;
}

.brand-color-contrast
{
    color: $brand-color-contrast;
}

.orange-color
{
    color: #ff9800;
}

.width-max
{
    width: 100%;
}

.boder
{
    border: 1px solid #333;
}

.bold
{
    font-weight: 700;
}

.mh-390
{
    min-height: 390px;
}

.test
{
    outline: 1px dotted red;
}

.rotate90
{
    transform: rotate(90deg);
}


.show-password {
    position: absolute;
    right: 10px;
    top: 13px;
    z-index: 9999999999999999;
}

.forgot-password {
    text-align: right;
    margin-top: 15px;
    margin-bottom: 26px;

    a {
        font-weight: bold;
    }
}


.forgot-signin-container {
    position: relative;

    .forgot-password-container, .sign-in-container {
        position: absolute;
        width: 100%;
        margin: 0 auto;
    }

    .sign-in-container {
        left: 9999px;
        opacity: 0;
    }
}



.banner
{
    min-height: 360px;
    text-align: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;



    background-position: top;
    background-size: cover;
    // background-attachment: fixed;
    background-repeat: no-repeat;
    &_title
    {
        font-size: 36px;
        font-weight: 500;
        line-height: 40px;
        padding: 12px 0;
    }
    &_text
    {
        font-weight: 700;
        font-size: 18px;
        line-height: 1.5em;
        padding: 12px 0;
    }
    &_button
    {
        padding: 12px 0;
    }
}

.logo
{
    display: flex;

    margin: 3px;

    text-decoration: none;

    align-items: flex-start;
    &:hover
    {
        text-decoration: none;
    }
     > img
    {
        width: 30px;
        padding-top: 4px;
    }
     > span
    {
        padding-left: 4px;

        color: $brand-color-contrast;
    }
}

.socal
{
    display: inline-block;
    overflow: hidden;

    width: 20px;
    height: 20px;
    margin: -4px 6px;

    text-indent: -9999px;

    color: transparent;
    background-image: url(../img/socal/youtube.svg);
    background-repeat: no-repeat;
    background-position: center center;
    &.facebook
    {
        background-image: url(../img/socal/facebook.svg);
    }
    &.twitter
    {
        background-image: url(../img/socal/twitter.svg);
    }
    &.linkedin
    {
        background-image: url(../img/socal/linkedin.svg);
    }
    &.youtube
    {
        background-image: url(../img/socal/youtube.svg);
    }
}

.button
{
    display: inline-block;

    padding: 10px;

    cursor: pointer;
    text-decoration: none;

    color: $brand-color;
    border: none;
    background: none;
    border-radius: 2px;
    text-align: center;
    &.np
    {
        padding: 0;
    }
    &.lg
    {
        padding: 10px 30px;
    }
    &.bright
    {
        color: #fff;
        background-color: $brand-color;
        transition: all .5s ease;
        &:hover
        {
            text-decoration: none;
            background-color: lighten($brand-color, 10%);
        }
    }
    &.orange
    {
        color: #fff;
        background: #ff9800;
        &:hover
        {
            text-decoration: none;
             background-color: lighten(#ff9800, 10%);
        }
    }
    &.light-btn
    {
        border-radius: 2px;
        box-shadow: 0 0 2px 0 rgba(0,0,0,.14), 0 2px 2px 0 rgba(0,0,0,.12), 0 1px 3px 0 rgba(0,0,0,.20);
        transition: all .5s ease;
        &:hover
        {
            text-decoration: none;
            box-shadow: 0 0 10px 0 rgba(0,0,0,.14), 0 2px 10px 0 rgba(0,0,0,.12), 0 1px 10px 0 rgba(0,0,0,.20);
        }
    }
    &.arrow
    {
        padding-right: 25px;

        background-image: url(../img/right_arrow.svg);
        background-repeat: no-repeat;
        background-position: center right;
        background-size: 20px;
    }
    &.btn-socal
    {
        font-size: 16px;

        padding-left: 70px;

        text-align: left;

        color: #fff;
        box-shadow: 0 0 2px 0 rgba(0,0,0,.14), 0 2px 2px 0 rgba(0,0,0,.12);
        transition: all .5s ease;

        &.google
        {
            background: url(../img/socal/google_logo-white.svg) no-repeat 20px center #4683ef;
            &:hover
            {
                background-color: lighten(#4683ef, 5%);
            }
        }
        &.linkedin
        {
            background: url(../img/socal/linkedin_logo-white.svg) no-repeat 20px center #0288d1;
            &:hover
            {
                background-color: lighten(#0288d1, 5%);
            }
        }
        &.facebook
        {
            background: url(../img/socal/facebook_logo-white.svg) no-repeat 25px center #3f51b5;
            &:hover
            {
                background-color: lighten(#3f51b5, 5%);
            }
        }

        &:hover
        {
            text-decoration: none;
            &.google
            {
                background-color: lighten(#4683ef, 10%);
                &:hover
                {

                }
            }
            &.linkedin
            {
                background-color: lighten(#0288d1, 10%);
            }
            &.facebook
            {
                background-color: lighten(#3f51b5, 10%);
            }
        }


    }
}

.video-section
{
    position: relative;

    min-height: 360px;

    text-align: center;

    background-position: center;
    background-size: cover;

    transition: all .5s ease;

    &:hover
    {
        filter: contrast(130%);
    }

     > a
    {
        font-size: 28px;
        line-height: 40px;

        position: absolute;
        top: 0;
        left: 0;

        display: block;

        width: 100%;
        height: 100%;
        padding-top: 172px;

        text-decoration: none;

        color: #fff;
        background-image: url('../img/youtube-white.svg');
        background-repeat: no-repeat;
        background-position: center 110px;
    }
}


.card-v1
{
    margin-top: 10px;
    margin-bottom: 10px;
     > .outer
    {
        display: flex;
        min-height: 300px;
        position: relative;
        text-align: center;
        color: #fff;
        background-color: black;
        background-position: center;
        background-size: cover;

        align-items: center;
        justify-content: center;
        .inner
        {
            margin: 30px;
        }
        .title
        {
            font-size: 28px;
            font-weight: bold;
            font-weight: 300;
            line-height: 1.43;

            text-align: center;

            color: #fff;
        }
        .text
        {
            font-size: 18px;
            font-weight: bold;
            font-weight: 300;

            text-align: center;

            color: #fff;
        }
        .button
        {
            display: inline-block;
            overflow: hidden;

            width: 30px;
            height: 0;
            padding: 0;

            transition: all .5s;

            opacity: 0;

             > img
            {
                max-height: 100%;
            }
        }
        &:hover .button
        {

            height: 30px;

            opacity: 1;
        }
    }
    &.active
    {
        .button
        {

            height: 30px;

            transform: rotate(90deg);

            opacity: 1;
        }
    }
}

.card
{
    display: inline-block;

    width: 280px;
    margin: 10px;
    padding: 20px;

    vertical-align: top;

    background: #fff;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.20);
}

.tegs
{
    overflow: auto;

    max-width: 100%;

    text-align: center;
    white-space: nowrap;
    .teg
    {
        display: inline-block;

        margin: 10px 20px;
        padding: 0;

        transition: all .5s ease;

        color: #86888a;
        border-radius: 30px;
        cursor: pointer;
        &.active,
        &:hover
        {
            margin: 5px;
            padding: 5px 15px;

            text-decoration: none;

            color: #9c27b0;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .2);
        }
    }
}

.side-list
{
    margin: 0;
    padding: 15px 0;
    list-style: none;
    li a
    {
        position: relative;
        display: block;
        font-size: 16px;
        color: #111;
        padding: 6px 16px;
        margin: 8px 0;
        &:after
        {
            display: none;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background-color: #FDA639;
            width: 3px;
            height: 100%;
        }
    }
    li a:hover,
    li.active a
    {
        font-weight: 700;
        font-size: 16px;
        color: #FDA639;
        text-decoration: none;
    }
    li.active a
    {
        &:after
        {
            display: block;
        }
    }
}



#accordeon
{
    width: 350px;
    border: 10px solid #fff;
    box-shadow: 0 0 10px grey;
    margin: 10px;
}

.acc-head {
    padding: 5px 10px;
    background: #c0c0c0;
    cursor: pointer;
}

.acc-body {
    padding: 10px;
    border-bottom: 1px solid #c0c0c0;
    margin-bottom: 5px;
    display: none;
}





.accordion
{
    width:100%;
    min-height:30px;
    margin:20px auto;
    .data
    {
        display:none;
        padding: 0 20px;
        transition: padding .5s ease 1s;
    }

    .accordion-item
    {
        margin: 3px 5px;
        padding: 10px 5px;

        cursor:pointer;

        color: #000;

        font-weight: 700;
        font-size: 16px;
        color: #FFB74D;
        transition: all 1s ease;

        &.open
        {
            color: #424242;
            border-bottom:0px;
        }

        & .type
        {
            float: right;

            padding: 10px;
            background: url(../img/arrow-gray-bottom.svg) no-repeat center center;
            transition: all .5s ease;

        }

        &.open .type
        {
            float: right;

            padding: 10px;

            transform: rotate(180deg);

        }

    }
    .line
    {
        height: 1px;
        background: #ddd;
    }
}

.alert {
    font-size: 14px;
    position: relative;
    padding: .55rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.tooltip {
    width: 100%;
    position: absolute;
    z-index: 1070;
    display: block;
    font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.5;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    font-size: .875rem;
    word-wrap: break-word;
    position: relative;
    display: inline-block;
    margin: 10px 0px;
    opacity: 1;
}

.tooltip.bs-tether-element-attached-top, .tooltip.tooltip-bottom {
    padding: 5px 0;
    margin-top: 2px !important;
}

.tooltip-inner {
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    background-color: #be4b49;
    border-radius: .25rem;

    box-shadow: 0 0 0 1px rgba(139, 3, 0, .75), 0 1px 10px rgba(0, 0, 0, .35);
}

.tooltip.bs-tether-element-attached-top .tooltip-inner::before, .tooltip.tooltip-bottom .tooltip-inner::before {
    top: 0;
    left: 50%;
    margin-left: -5px;
    content: "";
    border-width: 0 5px 5px;
    border-bottom-color: #be4b49;
}

.tooltip-inner::before {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

@media (max-width: 768px) {
    .card-v1>.outer .title {
        text-align: left !important;
    }
}

//color palette
$primary-color: #e2001a;
$form-color: #000;
$active-font: #3c3c3c;
$theme-color: $brand-color;
$error-color: #d50000;
.contact-wrap
{
    width: 240px;
    margin: auto;
}

//contact form
.material-form
{
    .input-block
    {
        position: relative;

        width: 100%;
        height: 50px;
        margin-bottom: 10px;
        padding: 0;

        border-bottom: solid 1px #aaa;

        @include transition(all .3s ease-out);
        &::before
        {
            position: absolute;
            bottom: 0;
            left: 50%;

            width: 0;
            height: 2px;

            content: '';

            background-color: $theme-color;

            @include transition(all .3s ease-out);
        }
        &.focus
        {
            border-color: #fff;
            label
            {
                font-size: 13px;

                top: 0;

                color: $brand-color;
            }
            &::before
            {
                left: 0;

                width: 100%;
            }
        }
        &.has-value
        {
            label
            {
                font-size: 13px;

                top: -5px;
            }
        }
        &.error
        {
            border-color: #fff;
            &::before
            {
                left: 0;

                width: 100%;

                background-color: $error-color;
            }
        }
        &.textarea
        {
            height: auto;
            .form-control
            {

                height: auto;
                padding-top: 20px;

                resize: none;
            }
        }
        label
        {
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;

            position: absolute;
            z-index: 1;
            top: 20px;
            left: 0;

            overflow: hidden;

            width: 100%;
            height: 20px;
            margin: 0;

            white-space: nowrap;

            color: $form-color;

            @include transition(all .3s ease-out);
        }
        .form-control
        {
            font-size: 14px;
            font-weight: 500;

            position: relative;
            z-index: 2;

            width: 100%;
            height: 100%;
            padding: 14px 0 0;

            color: $active-font;
            border: none;
            background-color: transparent;

            @include border-radius(0);
            @include box-shadow(none);
        }
        .form-control:focus
        {
            outline: none;
            label
            {
                top: 0;
            }
        }
    }
    .error-label
    {
        font-size: 14px;

        margin-top: -15px;
        margin-bottom: 10px;

        color: $error-color;
    }
    .form-note
    {
        font-size: 13px;

        margin-top: -15px;
        margin-bottom: 15px;

        color: $form-color;
    }
    .square-button
    {
        font-size: 20px;
        font-weight: 700;

        width: 100%;
        height: 60px;
        padding: 0;

        cursor: pointer;
        text-align: center;
        text-transform: uppercase;

        color: #fff;
        border: none;
        background-color: lighten($theme-color, 5%);

        @include border-radius(0);
        @include transition(all .3s ease);
        &:hover,
        &:focus
        {
            background-color: darken($theme-color, 5%);
        }
    }
    .button
    {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;

    }

    .select2-container--default .select2-selection--single
    {
        border: none;
        border-bottom: 1px solid #333;
        border-radius: 0;
    }
    .select2-container
    {
        display: block;
        width: 100% !important;
        &:after
        {
            position: absolute;
            z-index: 999;
            bottom: -1px;
            left: 50%;

            display: block;

            width: 0;
            height: 4px;

            content: '';

            background: $theme-color;
            transition: all .5s ease;
        }
        &--open:after
        {
            width: 100%;
            left: 0;
        }
        
    }

    .select2-container .select2-selection--single .select2-selection__rendered
    {
        padding-left: 0;
    }

    .select2-container--default .select2-selection--single
    {
        background: none;
    }

    &.big
    {
        .select2-container .select2-selection--single
        {
            height: 35px;
        }
    }

}

.flag-image {
    margin: 0 10px 0 10px;
    position: relative;
    top: 2px;
    img {
        width: 20px;
    }
}

.select2-container--default .select2-selection--single
{
    background: none;
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #F3F3F3 !important;
    border-left: 2px solid $brand-color;
}
.select2-container--default .select2-results__option--highlighted {
    background-color: #F3F3F3 !important;
    color: black !important;
}

.select2-container--open .select2-dropdown--below
{
    border-radius: 0;
}

/*dropdown list as ul li method*/

.select-block
{
    position: relative;

    width: 100%;
    margin-bottom: 10px;
    padding: 12px 0 0;

    $size: 45px;
    $padding: 15px;
    @include transition(all .3s ease-out);
    label
    {
        display: inline-block;
        font-size: 14px;
        font-weight: 500px;
        font-weight: 500;
        line-height: 10px;
        padding-bottom: 10px;

        margin: 0;


        color: $gray-600;

        @include transition(all .3s ease-out);
    }
    .active-list
    {
        font-size: 0;
        font-weight: 500;

        position: relative;
        z-index: 2;

        overflow: hidden;

        width: 100%;
        height: $size;
        padding: 0;
        padding-right: 20px;

        cursor: pointer;
        white-space: nowrap;

        color: #c9c9c9;

        @include box-sizing(border-box);
        &:after
        {
            font-size: 14px;
            line-height: $size;

            position: absolute;
            top: 50%;
            right: 0;

            width: 20px;
            height: $size;
            margin-top: -$size/2;
            padding-left: 5px;

            content: '▼';
            text-align: left;

            color: $form-color;
        }
    }
    &.added
    {
        label
        {
            font-size: 13px;

            top: 0;
        }
        .active-list
        {
            font-size: 12px;

            color: $active-font;
        }
    }
    &.added.focus
    {
        label
        {
            top: 0;
        }
    }
    &.focus
    {
        label
        {
            font-size: 13px;

            top: 0;

            color: $theme-color;
        }
        .active-list
        {
            font-size: 12px;
        }
        .custom-select
        {
            border-color: #fff;
        }
        .active-list:after
        {
            content: '▲';

            color: $active-font;
        }
        .custom-select::before
        {
            left: 0;

            width: 100%;
        }
    }
    .custom-select
    {
        line-height: $size;

        position: relative;

        display: inline-block;

        width: 100%;
        height: $size;
        padding: 0;

        border-bottom: solid 1px #c8c8c8;
        &::before
        {
            position: absolute;
            bottom: 0;
            left: 50%;

            width: 0;
            height: 2px;

            content: '';

            background-color: $theme-color;

            @include transition(all .3s ease-out);
        }
        .list-field
        {
            position: absolute;
            top: 0;
            left: 0;

            display: none;

            width: 100%;
            height: $size;
            padding: 0 $padding;
        }
        .drop-down-list
        {
            position: absolute;
            z-index: 99;
            top: 100%;
            left: 0;

            display: none;
            overflow-x: auto;

            width: 100%;
            height: auto;
            max-height: 250px;
            margin: 0;
            margin-top: 2px;
            padding: 0;

            background-color: white;

            @include box-shadow(rgba(0, 0, 0, .117647) 0px 1px 6px, rgba(0, 0, 0, .117647) 0px 1px 4px);
            li
            {
                font-size: 12px;
                line-height: normal;

                padding: $padding*.70 $padding;

                cursor: pointer;

                background-color: #fafafa;
                background-color: #fff;

                @include transition(all .3s ease-out);
                input[type='checkbox']
                {
                    position: relative;
                    top: 1px;

                    margin: 0;
                    margin-right: 8px;
                }
                label
                {
                    font-weight: normal;

                    display: block;

                    cursor: pointer;
                }
            }
            li:hover
            {
                color: $active-font;
                background-color: rgba(black, .05);
            }
        }
        .drop-down-list .add-new
        {
            padding: $padding;

            color: $active-font;
            border-top: solid 1px #c8c8c8;
            i
            {
                font-size: 16px;

                margin-top: 3px;

                color: $form-color;
            }
        }
    }
}

//tablet and above devices
@include media-breakpoint-up(sm)
{
    .material-form
    {
        .input-block
        {
            label
            {
                font-size: 18px;
                line-height: 20px;
            }
            .form-control
            {
                font-size: 18px;
            }
        }
    }
    .select-block
    {
        label
        {
            font-size: 16px;
            line-height: 14px;
        }
        &.added
        {
            .active-list
            {
                font-size: 18px;
            }
        }
        &.focus
        {
            .active-list
            {
                font-size: 18px;
            }
        }
        .custom-select
        {
            .drop-down-list
            {
                li
                {
                    font-size: 15px;
                }
            }
        }
    }
    // .material-form {
    //   padding: 30px;
    // }
}

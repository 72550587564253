.footer
{
    margin-top: 30px;
    padding: 32px 0;

    text-align: center;

    // background-color: #fafafa;
    // background-color: #f5f8ff;
    // background-color: #def0f9;
    background-color: #f6efe8;

    @include media-breakpoint-up(md)
    {
        padding: 40px 0;

        text-align: left;
    }
    &_socals
    {
        margin-top: -6px;
        margin-left: -6px;

        text-align: right;

        @include media-breakpoint-up(md)
        {
            text-align: left;
        }
    }
    &_links
    {
        margin-top: 28px;
        .title
        {
            font-weight: 400;
            font-size: 16px;
            line-height: 1.5;

            text-decoration: none;
            text-transform: uppercase;

            color: #fb8c00;
        }
        ul.links
        {
            margin: 16px 0;
            padding: 0;

            list-style: none;
            li > a
            {
                font-weight: 400;
                font-size: 16px;
                line-height: 2.0;

                text-align: left;

                color: #86888a;
            }
        }
    }
}

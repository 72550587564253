.hiw-section
{
    display: flex;
    flex-direction: column;

    margin: 30px auto;
    padding: 8%;

    &.col-reverse
    {
        flex-direction: column-reverse;
    }

    .text-col
    {
        padding: 15px 5px;

        @include media-breakpoint-up(lg)
        {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }

    .text
    {
        position: relative;

        overflow: hidden;

        margin: 10px 0;

        transition: height .5s;

        .h
        {
            font-weight: 300;

            color: $gray-800;
        }

        &[aria-expanded='false']:after
        {
            position: absolute;
            bottom: 0;
            left: 0;

            display: block;

            width: 100%;
            height: 10px;

            content: '';

            // background: linear-gradient(to bottom, transparent, $gray-100);
        }
    }
}

@include media-breakpoint-up(md)
{
    .hiw-section
    {
        flex-direction: row;

        margin: 50px auto;
        padding: 0 8% 0 1%;

        align-items: center;
        align-items: stretch;
        .image-col
        {
            position: relative;
            .image
            {
                position: absolute;
                top: 5px;
                right: 5px;
                left: auto;

                min-width: 100%;
                height: 100%;

                text-align: center;
                img
                {
                    max-width: none;
                    height: 100%;
                    max-height: 360px;

                    @include media-breakpoint-up(lg)
                    {
                        max-height: 400px;
                        max-width: 600px;
                    }
                }
            }
        }
        &.bulging
        {
            .image-col
            {
                margin-top: -15px;
                margin-bottom: -15px;

                @include media-breakpoint-up(lg)
                {
                    margin-top: -30px;
                    margin-bottom: -30px;
                }
            }
        }
        &.reverse
        {
            flex-direction: row-reverse;

            padding: 0 1% 0 8%;
            .image
            {
                right: auto;
                left: 5px;
            }
        }
    }
}


.home-section {
    min-height: calc(100vh - 69px);

    .description-section {
        height: 100%;
        padding: 45px 65px;

        .description-container {
            .description-header {
                font-size: 2.345rem;
                margin: 0.321rem;
            }
        }

        .description-bottom {
            font-size: 18px;
            line-height: 30px;
            margin-top: 75px;
            margin-bottom: 30px;
            max-width: 350px;
            border-top: 0px solid rgba(0,0,0,0.1);
            color: #273240;
            padding-top: 15px;
        }
    }

    .image-section {
        height: calc(100vh - 69px);
        background-color: #f6efe8;
        position: relative;

        .img-fade {
            background-size: contain;
            width: 100%;
            height: 100%;     
            background-position: center;
            background-repeat: no-repeat;
            position:absolute;
            -webkit-animation-name: fade;
            -webkit-animation-iteration-count: infinite;
            -webkit-animation-duration: 80s;
            animation-name: fade;
            animation-iteration-count: infinite;
            animation-duration: 80s;

            &.first-image {
                background-image: url("/img/graypes_auiWeb_home_h1.jpg");
            }

            &.second-image {
                background-image: url("/img/graypes_auiWeb_home_h2.jpg");
                animation-delay: -70s;
            }

            &.third-image {
                background-image: url("/img/graypes_auiWeb_home_h3.jpg");
                animation-delay: -60s;
            }

            &.fourth-image {
                background-image: url("/img/graypes_auiWeb_home_h4.jpg");
                animation-delay: -50s;
            }

            &.fifth-image {
                background-image: url("/img/graypes_auiWeb_home_h5.jpg");
                animation-delay: -40s;
            }

            &.sixth-image {
                background-image: url("/img/graypes_auiWeb_home_h6.jpg");
                animation-delay: -30s;
            } 

            &.seventh-image {
                background-image: url("/img/graypes_auiWeb_home_h7.jpg");
                animation-delay: -20s;
            }

            &.eighth-image {
                background-image: url("/img/graypes_auiWeb_home_h8.jpg");
                animation-delay: -10s;
            }                         
        }
    }
}

@-webkit-keyframes fade {
    0% {opacity: 0;}
    10% {opacity: 1;}
    20% {opacity: 1;}
    30% {opacity: 0;}
    40% {opacity: 0;}
    60% {opacity: 0;}
    70% {opacity: 0;}
    80% {opacity: 0;}
    90% {opacity: 0;}
    100% {opacity: 0;}
}

@keyframes fade {
    0% {opacity: 0;}
    10% {opacity: 1;}
    20% {opacity: 1;}
    30% {opacity: 0;}
    40% {opacity: 0;}
    60% {opacity: 0;}
    70% {opacity: 0;}
    80% {opacity: 0;}
    90% {opacity: 0;}
    100% {opacity: 0;}
}

@media (max-width: 480px) {
    .home-section {
        .description-section {
            .description-container {
                .description-header {
                    font-size: 1.642rem;
                }
            }
        }
    }
}
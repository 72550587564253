.event
{
    padding: 15px;

    &_card
    {
        position: relative;

        overflow: hidden;

        max-width: 380px;
        height: 100%;
        margin: auto;

        transition: all .5s ease;

        border-radius: 2px;
        background: #fff;
        box-shadow: 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12), 0 3px 5px 0 rgba(0,0,0,.20);

        @include media-breakpoint-up(md)
        {
            &:hover
            {
                z-index: 999;

                transform: scale(1.05);

                box-shadow: 0 6px 20px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12), 0 3px 25px 0 rgba(0,0,0,.20);
                img
                {
                    transform: scale(1.05);
                }
            }
        }

        p
        {
            font-size: 14px;
            line-height: 24px;

            margin: 4px 0;
        }

        .h
        {
            font-size: 18px;

            color: rgba(0,0,0,.87);
        }
        &_href
        {
            position: absolute;
            top: 0;
            left: 0;

            display: block;

            width: 100%;
            height: 100%;

            opacity: .5;
        }
        &_image
        {
            overflow: hidden;

            height: 210px;
            img
            {
                min-width: 100%;
                min-height: 100%;

                transition: all .5s ease;

                object-fit: cover;
            }
        }
        &_text
        {
            display: flex;

            padding: 10px;
        }
        &_date
        {
            text-align: center;

            flex: 0 0 60px;
            &_month
            {
                font-size: 16px;

                color: #9013fe;
            }
            &_day
            {
                font-size: 16px;

                color: #000;
            }
        }
    }


    &_detalis
    {
        &_header
        {
            overflow: hidden;
            position: relative;
            &_image
            {
                position: relative;
                min-height: 360px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 30px;
                @include media-breakpoint-up(lg)
                {
                    padding: 100px;
                    padding-right: 400px;
                    text-align: left;
                    align-items: left;
                }
                *
                {
                    color: #fff;
                }
                h2
                {
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 40px;
                    margin-bottom: 0;
                }
                p
                {
                    font-weight: 700;
                    font-size: 18px;
                }
            }
            &_card
            {
                background: rgba(255, 255, 255, 0.95);
                border: 1px solid #D4D4D4;
                padding: 24px 32px;
                max-width: 100%;
                &_date
                {
                    p
                    {
                        margin: 0;
                        font-weight: 700;
                        font-size: 16px;
                        color: #000000;
                    }
                }
                &_text
                {
                    h2, p
                    {
                        margin: 8px 0;
                    }
                    h2
                    {
                        font-weight: 400;
                        font-size: 24px;
                        color: rgba(0,0,0,0.87);
                    }
                }
                &_button
                {
                    .button
                    {
                        display: block;
                        background: #FFB800;
                        border-radius: 2px;
                        font-weight: 400;
                        font-size: 16px;
                        color: #FFFFFF;
                        letter-spacing: 0.57px;
                        text-align: center;
                        padding: 10px 15px;
                        transition: all .5s ease;
                        &:hover
                        {
                            text-decoration: none;
                            background: #fac029;
                            box-shadow: 0 0 15px rgb(199, 200, 122)
                        }
                    }
                }
                @include media-breakpoint-up(lg)
                {
                    position: absolute;
                    right: 0;
                    top: 0;
                    border: none;
                    width: 400px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
            }
        }
    }
}

:focus
{
    outline: none;
}

body
{
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 1.2em;

    overflow-x: hidden;

    min-width: 360px;
    &.open-menu
    {
        overflow: hidden;
    }
}

img
{
    max-width: 100%;
}

a
{
    text-decoration: none;

    color: #4184f3;
    &:hover
    {
        text-decoration: underline;
    }
}

h1,
.h1
{
    font-size: 36px;
    font-weight: 300;
    line-height: 1.11;

    color: #424242;

    @include media-breakpoint-down(sm)
    {
        font-size: 24px;
    }
}

h2,
.h2
{
    font-size: 24px;
    font-weight: 300;
    line-height: 1.43;

    color: #424242;
}

h5,
.h5
{
    font-size: 16px;
    color: #222;
    line-height: 24px;
    font-weight: 700;
}

h6,
.h6
{
    margin: 5px 0;
    font-weight: 700;
    font-size: 16px;
    color: #222;
    line-height: 24px;
}

p
{
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5em;
    color: $gray-600;
    margin: 1rem 0;
    &.quote
    {
        font-size: 17px;
        font-weight: 700;

        margin-top: 10px;
        margin-bottom: 10px;
        padding: 15px 28px;

        color: #000;
        border-left: 4px solid #ff9800;
    }
}

ul
{
    list-style: none;
    padding: 0;
}

ul.list
{
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5em;
    color: $gray-600;
    margin: 1.5rem 0;
    padding: 0;
    list-style: none;
    li
    {
        padding: 3px 0 0 30px;
        position: relative;
        &::before
        {
            content: '•';
            display: inline-block;
            width: 3px;
            height: 3px;
            position: absolute;
            left: .5em;
            top: .2em;
        }
    }
}

table.table
{
    width: 100%;
    tr td
    {
        padding: 15px;
        vertical-align: top;
        font-size: 18px;
        font-weight: 300;
        color: #000000;
        line-height: 34px;
        &:first-child
        {
            padding-left: 0;
        }
        &:last-child
        {
            padding-right: 0;
        }
    }
}

.font-large
{
    @include media-breakpoint-down(sm)
    {
        font-size: 18px;
    }
}

.silver-bg
{
    // background-color: $gray-100;
    // background-color: #f5f8ff;
    // background-color: #def0f9;
    background-color: #f6efe8;
}

.page
{
    min-height: 100vh;
}


.owerlay
{
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;

    display: none;

    width: 100%;
    height: 100%;

    transition: opacity .5s;

    opacity: 0;
    background: #000;
    .open-menu &
    {
        display: block;

        animation: owerlay-show .3s;

        opacity: .6;
    }
}

@keyframes owerlay-show
{
    from
    {
        opacity: 0;
    }
    to
    {
        opacity: .6;
    }
}


.xs-container
{
    max-width: 400px;

    @include make-container();
}

.sm-container
{
    max-width: 620px;

    @include make-container();
}

.md-container
{
    max-width: 820px;

    @include make-container();
}

.brand-section
{
    margin: 30px auto;
    .brand
    {
        padding: 30px;
    }
}

.section
{
    padding-top: 30px;
    padding-bottom: 30px;
}

.text-bold {
    font-weight: bold;
}

.tooltip-modal {
    position: relative;
    display: inline-block;
}

.tooltip-modal .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    top: 20px;
    z-index: 1;

    &.copyto {
        display: block;
    }

    &.copied {
        display: none;
    }
}

.card-share:focus .copyto {
    display: none;
}

.card-share:focus .copied {
    display: block;
}

.tooltip-modal:hover .tooltiptext {
visibility: visible;
}

.graypes-card {
    box-shadow: 0 2px 7px 1px rgba(0,0,0,.1);
    background-color: #fff;
    border: .5px solid #eae8e8;
    margin-bottom: 10px;
    margin-top: 35px;
    height: 700px;
    padding: 25px;
    border-radius: 3px;

    transition: all .5s ease;

    &:hover {
        z-index: 999;
        transform: scale(1.05);
        box-shadow: 0 6px 20px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12), 0 3px 25px 0 rgba(0,0,0,.20);
    }

    .graypes-card-title {
        font-weight: bold;
    }
    span, label {
      color: #9E9E9E
    }
    
    .graypes-card-container{
      overflow-y: auto;
      max-height: 400px;
    }
  
    .graypes-card-header {
        position: relative;
  
        .graypes-card-rank {
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 25px;
            background-color: #a349b3;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            height: 50px;
            width: 50px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }
  
        .graypes-card-header-img-container {
            margin: 0 auto;
            max-width: 220px;
            max-height: 220px;
            padding-top: 25px;
        }
    }
    
    .graypes-card-footer {
        position: absolute;
        width: 100%;
        bottom: 25px;
        left: 0;
  
        .graypes-card-footer-actions {
            display: flex;
            align-items: center;
            justify-content: space-around;
    
            .card-action {
                color: #ff9800;
                font-size: 18px;
            }
            .card-share {
                width: 30px;
                height: 30px;
                background-repeat: no-repeat;
                background-size: contain;
                background-image: url(../img/share-icon.png);
                color: #000;
                i {
                    font-size: 32px;
                }
            }
        }
    }

    
}


.graypes-card-mini {
    box-shadow: 0 2px 7px 1px rgba(0,0,0,.1);
    background-color: #fff;
    border: .5px solid #eae8e8;
    margin-bottom: 10px;
    margin-top: 35px;
    height: 400px;
    padding: 25px;
    border-radius: 3px;

    transition: all .5s ease;

    &:hover {
        z-index: 999;
        transform: scale(1.05);
        box-shadow: 0 6px 20px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12), 0 3px 25px 0 rgba(0,0,0,.20);
    }

    .graypes-card-title {
        font-weight: bold;
    }
    span, label {
      color: #9E9E9E
    }
    
    .graypes-card-container{
      overflow-y: auto;
      max-height: 400px;
    }
  
    .graypes-card-header {
        position: relative;
  
        .graypes-card-rank {
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 25px;
            background-color: #a349b3;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            height: 50px;
            width: 50px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }
  
        .graypes-card-header-img-container {
            margin: 0 auto;
            max-width: 220px;
            max-height: 220px;
            padding-top: 25px;
        }
    }
    
    .graypes-card-footer {
        position: absolute;
        width: 100%;
        bottom: 25px;
        left: 0;
  
        .graypes-card-footer-actions {
            display: flex;
            align-items: center;
            justify-content: space-around;
    
            .card-action {
                color: #ff9800;
                font-size: 18px;
            }
            .card-share {
                width: 30px;
                height: 30px;
                background-repeat: no-repeat;
                background-size: contain;
                background-image: url(../img/share-icon.png);
                color: #000;
                i {
                    font-size: 32px;
                }
            }
        }
    }

    
}



.message-modal {
    position: fixed;
    bottom: -15px;
    left: 15px;
    background-color: #fff;
    box-shadow: 0 2px 7px 3px rgba(0, 0, 0, 0.1);
    padding: 5px 15px;
    z-index: 999;
    opacity: 0;
    transition: all .5s ease;
    max-width: 320px;

    &.show-modal {
        opacity: 1;
        bottom: 15px;
    }

    .message-modal-close {
        position: absolute;
        right: 10px;
        top: 5px;
        color: #8e8e8e;
        cursor: pointer;
    }

    .message-modal-container {
        display: flex;
        align-items: center;

        .message-modal-img {
            width: 50px;
            height: 50px;
        }

        .message-modal-content {
            color: #000;
            margin-left: 10px;
            font-size: 12px;
        }
    }
    
}

#productIconsRow {
    img {
        width: 100%;
        height: 100%;
    }
}
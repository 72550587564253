.header
{
    box-shadow: 0 1px 11px 0 rgba(133,133,133,0.20);
    z-index: 999;
    position: sticky;   
    top: 0; 
    background-color: white;
}

.nav-bar
{
    position: relative;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: space-between;
    @include media-breakpoint-down(md)
    {
        padding: 10px 0 10px 40px;
         > .logo
        {
            padding-top: 5px;
        }
    }
     > .menu
    {
        @include media-breakpoint-down(md)
        {
            position: fixed;
            z-index: 999;
            top: 0;
            left: -500px;

            overflow: auto;

            width: 240px;
            height: 100%;

            transition: left .5s ease;

            background: #f1f1f1;
            .open-menu &
            {
                left: 0;
            }
        }
        ul
        {
            display: flex;
            flex-direction: row;

            margin: 0;
            padding: 0 10px;

            list-style: none;

            @include media-breakpoint-down(md)
            {
                flex-direction: column;

                margin: 15px 10px;
            }

            li
            {
                padding: 0 20px;
                 > a
                {
                    position: relative;

                    display: block;

                    padding: 25px 0;

                    text-decoration: none;

                    color: #86888a;

                    @include media-breakpoint-down(md)
                    {
                        padding: 10px 0;
                    }
                }
                & > a:after
                {
                    position: absolute;
                    bottom: 40%;
                    left: 0;

                    width: 100%;
                    height: 2px;

                    content: '';
                    transition: all .5s ease;

                    opacity: 0;
                    background: #fb8c00;
                }
                &.active > a:after,
                &:hover > a:after
                {
                    bottom: 0;

                    opacity: 1;
                }
            }
        }
    }
    .menu-togge
    {
        position: absolute;
        left: 0;

        display: none;
        overflow: hidden;

        width: 24px;
        height: 24px;

        text-indent: -999px;

        border: none;
        background: transparent url('../img/menu.svg') center center no-repeat;

        @include media-breakpoint-down(md)
        {
            display: block;
        }
    }
}
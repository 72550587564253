.prices
{
    padding: 56px 15px 30px;

    text-align: center;

    .prices_card
    {
        position: relative;

        display: inline-block;

        width: 280px;
        margin: 10px;
        padding: 40px 20px 10px;

        transition: transform .5s ease, box-shadow .5s ease;
        vertical-align: top;

        border-radius: 4px;
        background-color: #fff;
        box-shadow: 0 1px 3px 0 rgba(0,0,0,.2);

        @include media-breakpoint-up(md)
        {
            &:hover
            {
                z-index: 100;

                transform: scale(1.1, 1.1);

                box-shadow: 0 1px 30px 0 rgba(0,0,0,.2);
            }
        }
        &.picked
        {
            border: 2px solid #9c27b0;
            &::after
            {
                font-size: 16px;
                line-height: 56px;

                display: block;

                height: 56px;
                margin: 10px -20px -10px;

                content: 'Most Picked';

                color: #fff;
                color: 100%;
                background-color: #9c27b0;
            }
        }
        &_title
        {
            font-size: 18px;
            font-weight: bold;
            line-height: 32px;

            color: #1e1e1e;
        }
        &_price
        {
            display: flex;
            display: block;

            padding-top: 24px;
            padding-bottom: 24px;

            align-items: center;
            justify-content: center;
             > .price
            {
                font-size: 32px;
                line-height: 32px;

                text-align: center;

                color: #1e1e1e;
            }
             > .period
            {
                padding-left: 5px;

                color: #86888a;
            }
        }
        &_table
        {
            font-size: 14px;

            width: 100%;

            color: #86888a;
            tr
            {
                padding-top: 15px;

                border-bottom: .5px solid rgba(142, 142, 142, .32);
                td
                {
                    padding: 15px 0 8px 0;
                    &:first-child
                    {
                        text-align: left;
                    }
                    &:last-child
                    {
                        text-align: right;

                        color: #1e1e1e;
                    }
                }
            }
        }
        &_button
        {
            font-size: 16px;

            display: inline-block;

            margin: 20px 0 10px 0;
            padding: 10px 40px;

            color: #fff;
            border-right: 2px;
            background-color: #ffb800;
        }
    }
}

.price-container {
    padding: 0 10%;
}

/* NOT SCROLLABLE THE INNOVATOR, CONSULTANT PLANS TABLES */
/*
.price-table-responsive {
    overflow: scroll;
}
*/

.price-table {
    // width: 960px;
    margin: 0 auto;
    font-family: Roboto,sans-serif;
}

.price-table-select-button {
    font-size: 14px;
    display: inline-block;
    margin: 20px 0 10px 0;
    padding: 10px 40px;
    color: #fff;
    border-right: 2px;
    background-color: #ffb800;
}

.price-body {
}

.price-row {
    padding: 8px;
}

.price-col {
    border: 1px solid #d7d7d7;   
    width: 18.7%;
    text-align: center;
    padding: 7px 10px;
}

.price-month {
    text-align: center;

    .price {
        font-size: 25px;
    }
    .period {
        font-size: 15px;
    }

    &.top {
        padding-bottom: 15px;
    }

    &.bottom {
        padding-top: 25px;
    }
}

.price-col-name {
    width: 25% !important;
    font-weight: bold;
    text-align: initial;    
}

.price-col-subname {
    text-align: left;
    padding-left: 20px;
}

.price-header-col {
    text-align: center;
    background-color: #a250b8;
    color: #fff;
    font-weight: bold;
    padding: 15px 30px;
}

.purple {
    color: #8f37a9;
}

.orange {
    color: #ffb436;
}

sup {
    font-size: 50% !important;
}

.business-idea-pricing-chart {
    height: 600px;
    position: relative;
    // max-width: 860px;
    // change max-width ==> width for NO-RESPONSIVE
    width: 860px;
    margin: 0 auto;

    .chart-left-side {
        height: 100%;
        width: 100px;
        position: absolute;
        .left-side-title {
            height: calc(100% - 100px);
            width: 100px;
            display: flex;
            align-items: center;
            position: absolute;

            label {
                position: absolute;
                transform: rotate(270deg);
                right: -50px;
                white-space: nowrap;
                font-size: 24px;
                color: #a250b8;
            }
        }

        

        .left-values {
            height: calc(100% - 100px);
            // position: absolute;
            // right: 25px;
            width: 1px;
            // display: flex;
            // flex-wrap: wrap;
            // align-items: flex-end;
            // justify-content: flex-end;

            h3 {
                color: #7f46c3;
                font-size: 24px;
                position: absolute;
                right: 25px;
            }

            .left-value-1 {
                bottom: 390px;
            }
            .left-value-2 {
                bottom: 250px;
            }
            .left-value-3 {
                bottom: 100px;
            }
        }
        
        .arrow-up {
            height: 90%;
            width: 5px;
            background-color: #a250b8;
            position: absolute;
            right: 0;
        }
    }

    .chart-right-side {
        height: calc(100% - 100px);
        width: 100px;
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    
        .box-right-side {
            border: 2px solid #c2c2c2;
            transform: rotate(270deg);
            font-size: 32px;
            white-space: nowrap;
            text-align: center;

            h3 {
                margin: 20px;
                font-weight: 400;
            }

            .contact-graypes {
                color: #feaa3b;
            }

            .contact-email {
                color: #7f46c3;
            }
        }
    }

    .chart-content {
        width: calc(100% - 200px);
        height: calc(100% - 100px);
        margin-left: 100px;
        padding: 30px;

        .chart-box {
            height: 33%;
            display: flex;
        }

        .chart-col {
            height: 100%;
            width: 33%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px dashed #dedede;
            padding: 20px;

            .chart-price {
                height: 100%;
                width: 100%;
                border: 2px solid #c2c2c2;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                color: #feaa3b;
                
                .top-box {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 5px;
                }

                .bottom-box {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: 5px;
                }

                sup {
                    font-size: 18px !important;
                    vertical-align: top;
                }

                label {
                    font-size: 36px;
                }

                hr {
                    width: 100%;
                    border: 1px solid #c2c2c2;
                }
            }
        }

    }

    .chart-bottom {
        height: 100px;
        width: 100%;
        position: absolute;
        bottom: 0;

        .arrow-right {
            width: 100%;
            height: 5px;
            background-color: #a250b8;
            position: absolute;
            top: 0;
        }

        .bottom-values {
            margin-left: 100px;
            display: flex;
            justify-content: space-between;
            padding: 0 35px;
            width: calc(100% - 200px);

            h3 {
                color: #7f46c3;
                font-size: 24px;
            }
        }

        .bottom-side-title {
            text-align: center;

            label {
                white-space: nowrap;
                font-size: 24px;
                color: #a250b8;
            }
        }

    }
}

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}


/* COMMENT THE NEXT LINES SO NO-RESPONSIVE THE EP, BP PRICING TABLES */
/*
@media (max-width: 768px) {
    .business-idea-pricing-chart {
        .chart-left-side {
            width: 40px;

            .left-values {

                h3 {
                    font-size: 20px;
                    right: 10px;
                }
            }

            .left-side-title {
                label {
                    right: 10px;
                    font-size: 16px;
                }
            }
        }

        .box-right-side {
            display: none;
        }

        .chart-content {
            width: calc(100% - 40px);
            margin-left: 40px;
            padding: 10px;

            .chart-col {
                padding: 10px;

                .chart-price {
                    sup {
                        font-size: 12px !important;
                    }
                    label {
                        font-size: 22px;
                    }

                    span {
                        font-size: 14px;
                    }
                }
            }
        }    

        .chart-bottom {
            .bottom-values {
                margin-left: 40px;
                padding: 0 15px;
                width: auto;

                h3 {
                    font-size: 20px;
                }
            }

            .bottom-side-title {
    
                label {
                    font-size: 16px;
                }
            }
        }
    }
}
*/

/*
@media (max-width: 536px) {
    .business-idea-pricing-chart {
        .chart-content {
            .chart-col {
                .chart-price {
                    sup {
                        font-size: 8px !important;
                    }
                    label {
                        font-size: 16px;
                    }

                    span {
                        font-size: 10px;
                    }
                }
            }
        }   
    }
}
*/
.tabs-slider
{
    &_wrpt
    {
        white-space: nowrap;
        overflow: hidden;
    }
    &_tab
    {
        display: inline-block;
        width: 100%;
        white-space: normal;
        vertical-align: top;
        // transition: margin .5s ease;
    }
}

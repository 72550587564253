.careers
{
    &_card
    {
        display: flex;
        align-items: center;

        height: 90px;
        margin: 10px auto;
        padding: 24px;

        transition: all .3s ease;

        color: #222;
        border: 1px solid #D4D4D4;
        border-radius: 2px;
        background: #FDFDFD;
        .icon {
            display: inline-block;

          width: 15px;
          height: 16px;

          background: url("../img/pencil.svg");
          background-size: 15px 16px;

          fill: #ffb600;
        }
        img
        {
            fill: red;
        }
        span
        {
            padding: 0 15px;
        }
        &:hover
        {
            transform: scale(1.01);
            text-decoration: none;

            color: $brand-color-contrast;
            background: #FFFFFF;
            box-shadow: 0 16px 24px 2px rgba(255,239,239,0.15), 0 6px 30px 5px rgba(132,132,132,0.12);
        }
        .button
        {
            padding: 10px 30px;
            margin-left: auto;
        }
    }
}
